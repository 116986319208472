@function rem($pixel-size, $base-size: $baseSize) {
  @if (unitless($pixel-size)) {
    $pixel-size: $pixel-size * 1px;
  }

  @return $pixel-size / $base-size * 1rem;
}

@function colors($key) {
  @return nth($list: $netsoft-colors, $n: $key);
}

@mixin netsoft-font-colors($colors: $netsoft-colors) {
  $index: 1;

  #{".color"} {
    @each $color in $colors {
      &#{if($index < 10, -c0#{$index}, -c#{$index})} {
        color: $color;
      }

      &-hover#{if($index < 10, -c0#{$index}, -c#{$index})} {
        &:hover {
          color: $color;
        }
      }

      $index: $index + 1;
    }
  }
}

@mixin netsoft-font-sizes() {
  $netsoft-size-labels: ("xs", "sm", "base", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl", "7xl");
  $netsoft-size-rem: (0.75rem, 0.875rem, 1rem, 1.125rem, 1.25rem, 1.5rem, 1.875rem, 2.25rem, 2.55rem, 3rem, 4rem);
  $index: 1;

  #{".text-"} {
    @each $label in $netsoft-size-labels {
      &#{$label} {
        @include font-size(nth($netsoft-size-rem, $index));
      }

      $index: $index + 1;
    }
  }
}

@mixin netsoft-background-colors($colors: $netsoft-colors) {
  $index: 1;

  #{".bg"} {
    @each $color in $colors {
      &#{if($index < 10, -c0#{$index}, -c#{$index})} {
        background: $color;
      }

      &-hover#{if($index < 10, -c0#{$index}, -c#{$index})} {
        &:hover {
          background: $color;
        }
      }

      $index: $index + 1;
    }
  }
}

@mixin netsoft-border-colors($colors: $netsoft-colors) {
  $index: 1;

  #{".border"} {
    @each $color in $colors {
      &#{if($index < 10, -c0#{$index}, -c#{$index})} {
        border-color: $color;
      }

      &-hover#{if($index < 10, -c0#{$index}, -c#{$index})} {
        &:hover {
          border-color: $color;
        }
      }

      $index: $index + 1;
    }
  }
}

@mixin netsoft-z-index($min: 1, $max: 10) {
  #{".z-index"} {
    @for $i from $min through $max {
      &-#{$i} {
        position: relative;
        z-index: $i;
      }
    }
  }
}

@include netsoft-font-sizes();
@include netsoft-font-colors();

@include netsoft-z-index();

@include netsoft-background-colors();
@include netsoft-border-colors();