@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$theme-colors: (
	"primary": #f56e2b,
	"secondary": #676767,
);

@import "_variables.scss";
@import "_mixins.scss";
@import "_customs.scss";

$enable-responsive-font-sizes: true;
$smallest_screen: 320px;

.theme-color {
	color: #f56e2b;
}

.theme-bg-color {
	color: #f56e2b;
}

#homeBannerSlider {
	.home-slide {
		&::before {
			content: '';
			background-color: rgba(0, 0, 0, 0.2);
			position: absolute;
			top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
		}

		background-size: cover;
		background-repeat: no-repeat;
		height: 100vh;
		width: 100%;
	}
}

.navbar-brand {
	.website-logo {
		width: 100%;
		max-width: 90px;
	}

	.website-name {
		color:#ffffff
	}
}

#main_features {
	.one-feature {
		.main-features-img {
			width: 100px;
		}

	}
	.main-features-bg-img {
		// height: 747px;
		// background-repeat: no-repeat;
		// background-position: center;
		// display: flex;
		// justify-content: center;
		// align-items: center;

		// img {
		// 	width: 50%;
		// 	object-fit: contain;
		// 	padding-left: 1rem;
		// }
	}

	@include media-breakpoint-down(md) {
		// .main-features-bg-img {
		// 	img {
		// 		width: 41%;
		// 	}
		// }
	}

	@include media-breakpoint-down(sm) {
		.one-feature {

		}

		// .main-features-bg-img {
		// 	img {
		// 		width: 75%;
		// 	}
		// }
	}
}

#boxes {
	.box {
		min-height: 344px;

		.box-img {
			width: 100%;
		}
	}
}

#ourPartner {
	.about-img {
		width: 83%;
	}

	@include media-breakpoint-down(md) {
		.about-img {

		}
	}
}

.inner-page-wrapper {
	&-content-title {
		font-size: 18px;
		font-weight: 600;
		max-width: 750px;
		color: #676767;
	}

	&-content-subtitle {
		font-size: 16px;
		max-width: 750px;
		color: #676767;
	}

	&.completed-page {
		i {
			width: 6rem;
			height: 6rem;
			line-height: 6rem;
			background-color: #f56e2b;
			display: inline-block;
			text-align: center;
			color: #ffffff;
			font-size: 2rem;
			border-radius: 100%;
		}
	}
}

.foxapp-header {
	@include media-breakpoint-down(md) {
		top: 0;
	}
}

#modalSuccess {
	.modal-content {
		border-radius: 1rem;
		overflow: hidden;

		.modal-header {
			.modal-close {
				cursor: pointer;
				position: absolute;
				right: 10%;
			}
		}
	}
}

.git-in-touch {
	background: #f56e2b;
}

.track-order-page {
	.style1 {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 14px;
		color: #660066;
		font-weight: bold;
	}
	.style2 {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 16px;
		color: #660066;
		font-weight: bold;
	}
	.style12 {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 26px;
		color: #F47B20;
		font-weight: bold;
	}
	.style13 {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 26px;
		color: #00549D;
		font-weight: bold;
	}
	.style14 {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 26px;
		color: #523031;
		font-weight: bold;
	}
}

.track-order-result-page {
	.style1 {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 14px;
		color: #660066;
		font-weight: bold;
	}
	.style2 {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 18px;
		color: #660066;
		font-weight: bold;
	}
}