.line-height-reset {
  line-height: 1 !important;
}

.spacing {
  &-normal {
    letter-spacing: 0;
  }

  &-wide {
    letter-spacing: 1.2px;
  }

  &-wider {
    letter-spacing: 0.05em;
  }

  &-widest {
    letter-spacing: 0.1em;
  }

  &-small {
    letter-spacing: 0.39px;
  }
}

.object-fit {
  &-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &-contain {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.opacity-0 {
  opacity: 0
}
.opacity-1 {
  opacity: 0.2;
}
.opacity-2 {
  opacity: 0.4;
}
.opacity-3 {
  opacity: 0.6;
}
.opacity-4 {
  opacity: 0.8;
}
.opacity-5 {
  opacity: 1;
}